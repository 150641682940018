<template>
    <div>
        <ScrollPanel style="width: 100%; height: 600px">
            <div class="p-grid">
                <DataTable :value="commissions" :filters="filters" scrollable :paginator="true" dataKey="id"
                           paginatorPosition="bottom" :rows="page.per_page " :lazy="true" :totalRecords="page.total"
                           :loading="loading"  @page="onPage($event)" class="content">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                <h5 class="p-mr-2">
                                   Comisiones
                                </h5>
                            </template>
                            <template #right>
                                <Button label="Nuevo registro" icon="pi pi-plus" class="p-button-success p-mr-2"
                                        @click="newCommission()" />
                            </template>
                        </Toolbar>
                    </template>

                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class"></Column>
                    <Column header="Opciones"  headerStyle="width: 150px">
                        <template #body="slotProps">
                            <Button v-tooltip="'Editar'" icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2" @click="edit(slotProps.data)"/>
                            <Button  v-tooltip="'Eliminar'" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteCommission($event, slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </ScrollPanel>
        <Dialog v-model:visible="editDialog" :style="{width: '400px'}" header="Datos de escala de comisión" :modal="true" class="p-fluid">
            <div class="p-grid">
                <div class="p-col-12 p-md-12">
                    <div class="p-field  p-fluid">
                                <span class="p-float-label">
                                    <AutoComplete id="car" :dropdown="true" :multiple="false" field="description"
                                                  v-model="data.position" :suggestions="positions"
                                                  @complete="getCatalog($event, 'positions', 'description')"
                                                  :class="{'p-invalid': submitted && (!data.position )}"/>
                                    <label for="car">Cargo</label>
                                </span>
                        <small class="p-invalid" v-if="submitted && !data.position">El cargo es requerido</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="value" type="number" v-model="data.value"
                                        :class="{'p-invalid': submitted && !data.value }" class="p-inputtext-sm" step="0.01" min="0"></input-text>
                            <label for="value">Valor</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.value">El valor es requerido</small>
                    </div>
                </div>
            </div>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveCommission"/>
            </template>
        </Dialog>
    </div>

</template>

<script>
    import catalogService from '../../service/catalog.service'
    import service from '../../service/commision.service'
    import {mapState} from "vuex";

    export default {
        name: "Commission",
        props: {
            plan: Object,
        },
        data() {
            return {
                service: service('commissions/scales'),
                columns: [
                    {field: 'position.description', header: 'Cargo'},
                    {field: 'value', header: 'Valor'}
                ],
                page: {
                    page: 1,
                    per_page: 5,
                    total: 0,
                    order: "desc"
                },
                commissions: [],
                positions: [],
                data: {},
                filters: {},
                submitted: false,
                loading: false,
                editDialog: false,
                verifyDataDialog: false
            }
        },
        methods: {
            deleteCommission(event, data) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Desea eliminar el registro?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.loading = true;
                        this.service.delete(data.id).then(() => {
                            this.getData();
                        }).catch(err => {
                            const message = err.response.data;
                            let error = this.$utils.formatError(message);
                            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                        }).finally(() => this.loading = false);
                    },
                    reject: () => {
                    }
                });
            },
            edit(val) {
                this.currentItem = val;
                this.data = val;
                this.$nextTick(() => {
                    this.editDialog = true;
                })

            },
            onPage(event) {
                this.page.page = event.page + 1;
                this.getData()
            },
            newCommission() {
                this.submitted = false;
                this.currentItem = null;
                this.data =  {};
                this.editDialog = true;
            },
            getData() {
                this.loading = true;
                this.service.getByPlan(this.plan.id, this.page).then(x => {
                    this.commissions = x.data.data;
                    this.page.total = x.data.total;
                }).catch(() => this.commissions = []).finally(() =>   {
                    this.loading = false
                })
            },
            formatData() {
                this.data.plan_id = this.plan.id;
                this.data.position_id = this.data.position.id;
            },
            saveCommission() {
                this.submitted = true;
                if(!this.data.position || !this.data.value) {
                    return;
                }
                this.formatData();
                this.service.save(this.data, this.currentItem?.id).then(() => {
                    this.getData();
                    this.editDialog = false;
                }).catch(err => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                })
            },
            getCatalog(event, route, field) {
                catalogService.getCatalog(event, route, field).then(x => {
                    this[route] = x;
                });
            }
        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">

    .p-field{
        margin-top: 30px !important;
    }
    .content {
        overflow-wrap: break-word;
    }

    ::v-deep .p-dialog-content{
        height: 100%;
        overflow: inherit!important;

    }


</style>
